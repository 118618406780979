import { sdk as authClientSdk } from "@asosteam/asos-web-auth-client";

import {
  getKeyStoreDataversion,
  getPageCountry,
  getPageECommerceStore,
  getPageLanguage,
} from "../document-handlers";

/* The identity isAuthenticated promise must be
 * wrapped in an async function to work correctly in
 * my-account.
 */

const getIdentitySdk = async () => {
  const context = {
    store: getPageECommerceStore(),
    keyStoreDataversion: getKeyStoreDataversion(),
    lang: getPageLanguage(),
    country: getPageCountry(),
  };

  const { authClient } = authClientSdk({
    clientId: "my-account",
    scopes: ["openid", "profile", "offline_access", "pii", "sensitive", "pci"],
    context,
  });

  await authClient.initialise();

  let customerDetails = {};

  if (authClient.isAuthenticated()) {
    customerDetails = authClient.getIdTokenClaims();
  }

  return {
    isAuthenticated: authClient.isAuthenticated,
    customer: {
      isAuthenticated: authClient.isAuthenticated,
      isRecognised: true,
      customerGuid: customerDetails?.customerGuid,
      customerId: customerDetails?.customerId,
      profile: {},
    },
    getAccessToken: authClient.getAccessToken,
    logout: authClient.logout,
    silentLogout: authClient.silentLogout,
    silentTokenRefresh: authClient.silentTokenRefresh,
    login: () => authClient.redirectToLogin(),
  };
};

export default getIdentitySdk;
