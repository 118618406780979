import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPageLoadCount } from "@state/analytics/page-load-count";
import * as types from "@state/analytics/types";
import { resetMenuClicked } from "@state/application/actions";
import { getMenuClicked } from "@state/application/selectors";
import { isPremierSuccessUrl } from "@state/premier/selectors";

import { emitter } from "./analyticsEmitter";
import { getBasicPayload } from "./analyticsUtils";
import { getPageLoadPayload } from "./pageLoadHandler";

export interface Variant {
  variantId: number;
}

export interface Item {
  name: string;
  productId: number;
  variantId: number;
  quantity: number;
  seller: object;
}
export interface DeliveryGroupDetail {
  variants: Array<Variant>;
  source: undefined | object;
  id: string;
}
export interface AllOrderType {
  orderReference: string;
  status: string;
  items: Array<Item>;
  deliveryGroupsDetail: Array<DeliveryGroupDetail>;
  orderTotal: object;
  productId: number;
  quantity: number;
}

export interface OrderDetailsType {
  orderReference: string;
  orderTotal: object;
  status: string;
  deliveryGroupsDetail: Array<DeliveryGroupDetail>;
}

export interface AnalyticsState {
  form: Array<object>;
  orders: {
    all: Array<AllOrderType>;
    details: Array<OrderDetailsType>;
  };
  router: {
    location: {
      pathname: string;
    };
  };
  user: {
    gender: string;
  };
  identity: {
    isRecognised: boolean;
  };
  socialAccounts: object | undefined;
  audienceSegments: {
    loaded: boolean;
    segments: { segmentId: string }[];
  };
}

export interface Payload {
  payload: any;
}

const usePageLoadAnalytics = (pageId: string, isPageDataLoaded: boolean) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isNewPage, setIsNewPage] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isPremier = pageId === "premier";

  //essentially the basic payload from analytics middleware
  const { trackingEvent, canSendPremier, pathname } = useSelector(
    (state: AnalyticsState) => {
      if (isNewPage && isPageDataLoaded) {
        const pageSpecificData = getPageLoadPayload(pageId, state, dispatch);
        const basicPayload = getBasicPayload(state);
        return {
          pathname: state.router.location.pathname,
          canSendPremier: getMenuClicked(state) || isPremierSuccessUrl(),
          trackingEvent: {
            id: types.PAGE_LOAD,
            payload: {
              ...basicPayload,
              ...pageSpecificData,
            },
          },
        };
      }
      return {
        pathname: state.router.location.pathname,
      };
    }
  );

  useEffect(() => {
    if (trackingEvent) {
      setIsNewPage(false);
    }
  }, [trackingEvent]);

  useEffect(() => {
    if (!isNewPage) {
      setIsNewPage(true);
    }
  }, [pageId, pathname]);

  useEffect(() => {
    setLoaded(false);
    if (
      isPageDataLoaded &&
      ((trackingEvent && !isPremier) || (isPremier && canSendPremier))
    ) {
      trackingEvent.payload.visitPageCount = getPageLoadCount();
      dispatch(resetMenuClicked());
      emitter(trackingEvent, pathname);
      setLoaded(true);
    }
  }, [isPageDataLoaded, pathname, trackingEvent]);

  return { loaded };
};

export default usePageLoadAnalytics;
