export const getIdentity = (state) => {
  return state.identity;
};

export const getLoaded = (state) => getIdentity(state).loaded;

export const getCustomerId = (state) => getIdentity(state).customerId;

const getAccessToken = (state) => {
  if (getLoaded(state) && getIdentity(state)?.accessToken) {
    return getIdentity(state).accessToken;
  }
  return null;
};

export const canChangePassword = (state) => {
  return getAccessToken(state)?.idp === "site" || false;
};
