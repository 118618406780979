import appConfig from "../../appConfig";

const getCountryCode = (countryCode, orderDeliveryAddressPostalCode) => {
  if (countryCode === "GB" && appConfig.enableReturnCountryCodeSanitisation) {
    const postCodeMap = {
      JE: "JE",
      GY: "GG",
    };
    const postcodePrefix = orderDeliveryAddressPostalCode.slice(0, 2);
    const mappedCountryCode = postCodeMap[postcodePrefix];
    return mappedCountryCode ? mappedCountryCode : countryCode;
  }

  return countryCode;
};

export default getCountryCode;
