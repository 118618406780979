import { connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";

import { dropOff } from "@configuration/return-items";

import { searchGoogleForDropOffLocations } from "@state/returns/actions";
import {
  getDropOffLocationsError,
  getIfNoPlacesWereFound,
  getPlacesError,
  getSearchedDropOffLocation,
  isRequestingDropOffPoints,
  isRequestingPlaces,
} from "@state/returns/selectors";

import { scrollErrorIntoView } from "@utils/scroll-into-view";

import DropOffForm from "./component.tsx";
import validate, { validateLocationResults } from "./validate";

export const getSearchInitialValue = (address = {}) =>
  [address.addressLine1, address.postalCode]
    .filter((addressLine) => addressLine)
    .join(", ");

export const onSubmit = async (
  values,
  dispatch,
  props,
  validateFn = validate,
  validateLocationResultsFn = validateLocationResults
) => {
  const errors = validateFn(values, props);
  const { deliveryAddress, searchGoogleForDropOffLocationsFn } = props;
  if (errors) {
    throw new SubmissionError(errors);
  } else {
    const inistialAddress = getSearchInitialValue(deliveryAddress);
    const hasOrderDefaultAddress = inistialAddress === values.search;

    const postalCode = hasOrderDefaultAddress ? deliveryAddress.postalCode : "";

    const locations = await searchGoogleForDropOffLocationsFn(
      values.search,
      deliveryAddress.countryCode,
      postalCode,
      deliveryAddress.postalCode
    );
    const hasInvalidLocations = validateLocationResultsFn(locations);
    if (hasInvalidLocations) {
      throw new SubmissionError(hasInvalidLocations);
    }
  }
};

export const onSubmitFail = (
  validationResults,
  dispatch,
  submitError,
  props,
  scrollErrorIntoViewFn = scrollErrorIntoView
) => {
  if (validationResults || submitError.errors) {
    scrollErrorIntoViewFn();
  }
};

const mapStateToProps = (state, ownProps) => {
  const { deliveryAddress } = ownProps;
  const isLoading =
    isRequestingDropOffPoints(state) || isRequestingPlaces(state);
  return {
    initialValues: {
      search:
        getSearchedDropOffLocation(state) ||
        getSearchInitialValue(ownProps.deliveryAddress),
    },
    deliveryAddress,
    searchError:
      (getPlacesError(state) || getDropOffLocationsError(state)) && !isLoading,
    noPlacesFound: getIfNoPlacesWereFound(state),
    loading: isLoading,
  };
};

const mapDispatchToProps = {
  searchGoogleForDropOffLocationsFn: searchGoogleForDropOffLocations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: dropOff.form,
    onSubmit,
    onSubmitFail,
  })(DropOffForm)
);
